<template>
  <v-row class='row-space'>
    <div class='font-label' :style='labelStyle'>
      {{ label }}
    </div>
    <v-combobox
      v-model="select"
      :items="options"
      :placeholder="placeHolder"
      validate-on-blur
      :item-text='itemText'
      :item-value='itemValue'
      filled
      dense
      multiple
      chips
      @change="change"
      @focusout="focusout"
      :rules='validationRules'
    >
      <template v-slot:selection="data">
        <v-chip
          :key="JSON.stringify(data.item)"
          v-bind="data.attrs"
          :input-value="data.selected"
          :disabled="data.disabled"
          @click:close="data.parent.selectItem(data.item)"
        >
          <v-avatar
            class="accent white--text"
            left
            v-text="data.item[itemText].slice(0, 1).toUpperCase()"
          ></v-avatar>
          {{ data.item[itemText] }}
        </v-chip>
      </template>
    </v-combobox>
  </v-row>
</template>

<script>

import Vue from 'vue';

export default Vue.extend({
  name: 'ComboboxInput',
  props: {
    label: { type: String, default: '' },
    labelWidth: { type: String, default: '100px' },
    labelHAlign: { type: String, default: 'left' },
    labelVAlign: { type: String, default: 'middle' },
    placeHolder: { type: String, default: '' },
    itemValue: { type: String, default: 'id' },
    itemText: { type: String, default: 'companyName' },
    value: { type: Array },
    validationRules: { type: Array },
    options: { type: Array, default: () => [] },
  },
  methods: {
    focusout() {
      this.$emit('focusout');
    },
    change() {
      this.$emit('change');
    },
  },
  computed: {
    labelStyle() {
      return 'width: '
        .concat(this.labelWidth)
        .concat('; text-align: ')
        .concat(this.labelHAlign)
        .concat('; vertical-align: ')
        .concat(this.labelVAlign);
    },
    select: {
      get() {
        return this.value;
      },
      set(values) {
        this.$emit('input', values);
      },
    },
  },
});
</script>

<style>
input {
  padding: 13px 0px 13px !important;
}
</style>

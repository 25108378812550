<template>
  <FormContent :selected="selected" class='pa-6'>
    <div class="user-container">

      <div class="user-row">
        <TextFieldInput
          v-model='form.name'
          label='Nome*'
          placeHolder='Informe o nome completo para identificação'
          :validationRules='[validationRules.name]'
          @focusout="validate('name')"/>
      </div>

      <div class="user-row">
        <div class="w-80">
          <TextFieldInput
            v-model='form.email'
            label='E-mail*'
            placeHolder='Informe o e-mail para realização do login no sistema'
            :validationRules='[validationRules.email]'
            @focusout="validate('email')"/>
        </div>
        <div class="status-toggle">
          <SwitchInput class="align-toggle" v-model='form.enabled' label='Status'/>
        </div>
      </div>

      <div class="user-row">
        <ComboboxMultipleInput
          v-model='form.profiles'
          label='Perfis*'
          placeHolder='Selecione os perfis de usuário'
          :options='profileList'
          itemText='name'
          itemValue='id'
          :validationRules='[validationRules.profiles]'
          @change="validate('profiles')"/>
      </div>

      <div class="user-row" v-if='isSellerProfile()'>
        <ComboboxMultipleInput
          v-model='form.sellers'
          label='Sellers*'
          placeHolder='Selecione os nomes fantasia dos sellers'
          :options='sellerList'
          itemText='companyName'
          itemValue='id'
          :validationRules='[validationRules.sellers]'
          @change="validate('sellers')"/>
      </div>

      <div class="user-row">
        <div class="w-41">
          <TextFieldInput
            :disabled="editing && !editPassword"
            v-model='form.password'
            label='Senha*'
            input-type='password'
            placeHolder='Mais que 6 caracteres e pelo menos uma letra, um número e um caractere especial'
            :validationRules='[validationRules.password]'
            @focusout="validate('password')"/>
        </div>
        <div class="w-59">
          <TextFieldInput
            :disabled="editing && !editPassword"
            class="align-confirm-password"
            labelWidth='250px'
            v-model='form.passwordConfirm'
            label='Confirmação de Senha*'
            input-type='password'
            placeHolder='Mais que 6 caracteres e pelo menos uma letra, um número e um caractere especial'
            :validationRules='[validationRules.password, validationRules.passwordConfirm]'
            @focusout="validate('passwordConfirm')"/>
        </div>
      </div>

      <div v-if="editing">
        <v-btn
          class="alter-password-button"
          @click="editPassword = !editPassword">
          Alterar senha
        </v-btn>
      </div>

    </div>
  </FormContent>
</template>

<script>

import FormContent from '@/components/template/form/FormContent';
import TextFieldInput from '@/components/template/form/input/TextFieldInput';
import SwitchInput from '@/components/template/form/input/SwitchInput';
import ComboboxMultipleInput from '@/components/template/form/input/ComboboxMultipleInput';

export default {
  name: 'GeneralDataForm',
  components: {
    FormContent,
    TextFieldInput,
    SwitchInput,
    ComboboxMultipleInput,
  },
  data: () => ({
    selected: false,
    editPassword: false,
  }),
  props: {
    title: {
      type: String,
      required: false,
      default: 'Informações Gerais',
    },
    form: {
      type: Object,
      default: () => ({}),
    },
    validationRules: {
      type: Object,
      default: () => ({}),
    },
    profileList: {
      type: Array,
      default: () => ({}),
    },
    sellerList: {
      type: Array,
      default: () => ([]),
    },
    editing: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    isSellerProfile() {
      for (let i = 0; i < this.form.profiles.length; i += 1) {
        if (this.form.profiles[i].name === 'SELLERS') {
          return true;
        }
      }
      return false;
    },
    validate(field) {
      this.$emit('validateRequired', this.$options.name, field, this.form[field]);
    },
  },
};
</script>

<style>
.user-container {
  margin: 30px;
}

.user-row {
  display: flex;
  margin: 20px 0;
}

.status-toggle {
  width: 20%;
  display: flex;
  align-items: center;
}

.align-toggle {
  margin-left: 18%;
}

.align-confirm-password {
  margin-left: 53px;
}

.alter-password-button {
  color: #FFFFFF !important;
  float: right;
  background: #07BEE7 !important;
}
</style>

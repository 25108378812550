<template>
  <v-container class="box-content pa-0 pl-4 pr-4 pt-4" fluid>
    <FormStepController
      v-on:formSubbmit="formSubbmit"
      @formDelete="formDelete"
      :form="form"
      :editing="editing"
      :steps="steps"
      :title="!editing ? 'Cadastrar Usuário' : 'Editar Usuário'"
      alertScreenTitle="cadastro de usuário"
      backAlertMessage="Deseja cancelar o cadastro deste usuário?"
    >
      <GeneralDataForm
        :form="form"
        :profileList="profileList"
        :sellerList="sellerList"
        :editing="editing"
        :validationRules="steps['GeneralDataForm'].rules"
        @validateRequired="validateRequired"
      />
    </FormStepController>
  </v-container>
</template>

<script>
import FormStepController from '@/components/template/form/FormStepController';
import GeneralDataForm from '@/views/user/forms/GeneralDataForm';
// eslint-disable-next-line object-curly-newline
import { post, put, get, remove } from '../../service/api';
import { routerPush } from '@/service/kplaceRouter';
import utils from '@/utils/utils';
import notification from '@/service/notification';
import { getSellers } from '@/service/user/user-service';

export default {
  data: () => ({
    editing: false,
    form: {
      name: '',
      profiles: [],
      sellers: [],
      email: '',
      password: '',
      passwordConfirm: '',
      enabled: true,
    },
    steps: {
      GeneralDataForm: {
        step: 1,
        status: {},
        required: {
          name: true,
          profiles: true,
          sellers: false,
          email: true,
          password: true,
          passwordConfirm: true,
        },
        rules: {
          name: (value) => (value && value.length > 0) || 'Campo `Nome` é Obrigatório.',
          profiles: 'Component Method',
          sellers: 'Component Method',
          email: 'Component Method',
          password: (value) =>
            // eslint-disable-next-line implicit-arrow-linebreak
            !!(
              value &&
              value.length > 6 &&
              value.match(/\S/g) &&
              value.match(/\d/g) &&
              value.match(/[@[}{,.^?!#$%&()~=+\-_/*\-+.|\]]/g) &&
              value.match(/[a-zA-Z]/g)
            ) || 'Obrigatório no mínimo uma letra, um número e um caractere especial.',
          passwordConfirm: 'Component Method',
        },
      },
    },
    profileList: [
      {
        id: '0d024a15-bd8a-4c00-886a-0e43d81e2eca',
        name: 'ADMINISTRADOR',
      },
      {
        id: '15ca4834-228f-41d4-ad84-f67566efb03a',
        name: 'SELLERS',
      },
    ],
    sellerList: [],
  }),
  components: {
    GeneralDataForm,
    FormStepController,
  },
  created() {
    if (this.$route.params.id) {
      this.editing = true;
    }
    utils.setStepStatus(this);
    utils.setStepRequireds(this);
    this.steps.GeneralDataForm.rules.profiles = this.profilesRule;
    this.steps.GeneralDataForm.rules.sellers = this.sellersRule;
    this.steps.GeneralDataForm.rules.passwordConfirm = this.passwordConfirmRule;
    this.steps.GeneralDataForm.rules.email = this.emailRule;
  },
  async mounted() {
    await this.getSellersHandler();
    if (this.editing) {
      await this.getUser(this.$route.params.id);
    }
  },
  methods: {
    profilesRule(value) {
      this.steps.GeneralDataForm.required.sellers = this.form.profiles.includes(this.profileList[1]);

      if (!!value && value.length > 0) {
        if (this.form.profiles.includes(this.profileList[0])) {
          if (this.form.profiles.includes(this.profileList[1])) return true;
          return 'Obrigatório inserir o Perfil Seller.';
        }

        return true;
      }

      return 'Campo `Perfil` é Obrigatório.';
    },
    sellersRule(value) {
      for (let i = 0; i < this.form.profiles.length; i += 1) {
        if (this.form.profiles[i].name === 'SELLERS') {
          return !!(value && value.length > 0) || 'Campo `Sellers` é Obrigatório.';
        }
      }
      return true;
    },
    passwordConfirmRule(value) {
      return value === this.form.password || 'As senhas são diferentes.';
    },
    emailRule(value) {
      // get(`/auth/user/verify-email/${value}`)
      //   .then((res) => {
      //     this.exists_email = res.data;
      //   });
      return utils.isEmailValid(value) ? true : 'Por favor, informe um e-mail válido.';
    },
    async getUser(id) {
      try {
        const { data } = await get(`ui-integrator/user/${id}`);
        const sellers = [];
        for (let i = 0; i < data.sellers.length; i += 1) {
          for (let j = 0; j < this.sellerList.length; j += 1) {
            if (data.sellers[i] === this.sellerList[j].id) {
              sellers.push(this.sellerList[j]);
            }
          }
        }
        this.form = {
          ...data,
          profiles: data.groups,
          sellers,
        };
      } catch (e) {
        notification('Erro ao recuperar o usuário', 'error');
      }
    },
    formSubbmit() {
      const body = {
        ...this.form,
        username: this.form.email,
        userGroupIdList: utils.listToIds(this.form.profiles),
        sellerId: this.form.sellers.length > 0 ? this.form.sellers[0].id : null,
        sellers: this.form.sellers.length > 0 ? this.form.sellers : [],
        sendemail: true,
      };
      if (!this.editing) {
        this.postUser(body);
      } else {
        this.putUser(body);
      }
    },
    postUser(body) {
      post('ui-integrator/user/create', body, {
        headers: {},
      })
        .then((response) => {
          if (response.status === 201) {
            notification('Usuário salvo com sucesso.', 'success');
            routerPush('/user');
          } else {
            notification('Por favor, preencha os campos obrigatórios.', 'error');
          }
        })
        .catch((error) => {
          notification(`Erro ao criar usuário: ${error.response.data.message}`, 'error');
        });
    },
    putUser(body) {
      put(`ui-integrator/user/update/${this.$route.params.id}`, body, {
        headers: {},
      })
        .then((response) => {
          if (response.status === 200) {
            notification('Usuário salvo com sucesso.', 'success');
            routerPush('/user');
          } else {
            notification('Por favor, preencha os campos obrigatórios.', 'error');
          }
        })
        .catch((error) => {
          notification(`Erro ao atualizar usuário: ${error.response.data.message}`, 'error');
        });
    },
    formDelete() {
      remove(
        `ui-integrator/user/${this.$route.params.id}`,
        {},
        {
          headers: {},
        },
      )
        .then((response) => {
          if (response.status === 204) {
            notification('Usuário removido com sucesso.', 'success');
            routerPush('/user');
          } else {
            notification('Erro ao remover usuário.', 'error');
          }
        })
        .catch((error) => {
          notification(`Erro ao remover o usuário: ${error.response.data.message}`, 'error');
        });
    },
    async getSellersHandler() {
      try {
        const currentUser = this.$store?.state?.main?.currentUser;

        const data = await getSellers(currentUser);
        this.sellerList = data;
      } catch (error) {
        console.error('Error on getSellersHandler', error);
        this.sellerList = [];
      }
    },
    validateRequired(tab, field, value) {
      this.steps[tab].required[field] = !(this.steps[tab].rules[field](value) === true);

      if (field === 'profiles') {
        this.validateRequired('GeneralDataForm', 'sellers', this.form.sellers);
      }

      utils.revalidate(this);
    },
  },
};
</script>

<style>
.box-content {
  background-color: rgb(237, 237, 237);
  height: calc(100% - 80px);
  margin-top: 80px;
}
</style>
